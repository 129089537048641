import React from 'react';
import { arrayOf, bool, object, func } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import config from '../../config';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  // sections
  SectionHero,
  SectionHowItWorks,
  SectionListings,
  SectionExperiences,
  SectionCategories,
  SectionJoin,
} from '../../components';
import { TopbarContainer } from '../../containers';
import { subscribeToList } from '../../ducks/mailchimp.duck';
import classNames from 'classnames';

import facebookImage from '../../assets/attractrFacebook-1200x630.jpg';
import twitterImage from '../../assets/attractrTwitter-600x314.jpg';
import css from './LandingPage.module.css';

export const LandingPageComponent = props => {
  const {
    history,
    intl,
    location,
    scrollingDisabled,
    listings,
    experiences,
    currentUser,
    queryListingsInProgress,
    queryListingsError,
    queryExperiencesInProgress,
    queryExperiencesError,
    onSubscribeToList,
    subscribeToListInProgress,
    subscribeToListError,
  } = props;

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  const isWithListingsLayout = config.landingPageLayout === 'with-listings';
  const isCurrentUserArtist =
    currentUser?.attributes?.profile?.publicData?.role === config.userRoles['artist'];

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      currentPage="LandingPage"
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.heroContainer}>
            <SectionHero
              history={history}
              location={location}
              intl={intl}
              onSubscribeToList={onSubscribeToList}
              subscribeToListInProgress={subscribeToListInProgress}
              subscribeToListError={subscribeToListError}
              isWithListingsLayout={isWithListingsLayout}
            />
          </div>
          {isWithListingsLayout ? (
            <ul className={css.sections}>
              <li className={css.section}>
                <div className={classNames(css.sectionContent, css.sectionSlider)}>
                  <SectionListings
                    intl={intl}
                    listings={listings}
                    queryListingsInProgress={queryListingsInProgress}
                    queryListingsError={queryListingsError}
                  />
                </div>
              </li>
              <li className={css.section}>
                <div className={css.sectionContent}>
                  <SectionExperiences
                    experiences={experiences}
                    queryExperiencesInProgress={queryExperiencesInProgress}
                    queryExperiencesError={queryExperiencesError}
                  />
                </div>
              </li>
              <li className={css.section}>
                <div className={css.sectionContent}>
                  <SectionHowItWorks />
                </div>
              </li>
              <li className={css.section}>
                <div className={css.sectionContent}>
                  <SectionCategories />
                </div>
              </li>
              <li className={css.section}>
                <div className={css.sectionContent}>
                  <SectionJoin
                    currentUser={currentUser}
                    isCurrentUserArtist={isCurrentUserArtist}
                  />
                </div>
              </li>
            </ul>
          ) : null}
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

LandingPageComponent.defaultProps = {
  currentUser: null,
  queryListingsInProgress: false,
  queryListingsError: null,
  queryExperiencesInProgress: false,
  queryExperiencesError: null,

  // mailchimp
  onSubscribeToList: null,
  subscribeToListInProgress: false,
  subscribeToListError: null,
};

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUser: propTypes.currentUser,
  onManageDisableScrolling: func.isRequired,

  listings: arrayOf(propTypes.listing),
  queryListingsInProgress: bool.isRequired,
  queryListingsError: propTypes.error,

  experiences: arrayOf(propTypes.listing),
  queryExperiencesInProgress: bool.isRequired,
  queryExperiencesError: propTypes.error,

  // mailchimp
  onSubscribeToList: func.isRequired,
  subscribeToListInProgress: bool.isRequired,
  subscribeToListError: propTypes.error,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    listingIds,
    queryListingsInProgress,
    queryListingsError,
    experienceIds,
    queryExperiencesInProgress,
    queryExperiencesError,
  } = state.LandingPage;
  const { subscribeToListInProgress, subscribeToListError } = state.mailchimp;

  const listings = getListingsById(state, listingIds);
  const experiences = getListingsById(state, experienceIds);

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    listings,
    experiences,
    queryListingsInProgress,
    queryListingsError,
    queryExperiencesInProgress,
    queryExperiencesError,
    subscribeToListInProgress,
    subscribeToListError,
  };
};

const mapDispatchToProps = dispatch => ({
  onSubscribeToList: bodyParams => dispatch(subscribeToList(bodyParams)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(LandingPageComponent);

export default LandingPage;
